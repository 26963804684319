// stylesheets that are required by the application.
// The styles provided here are not supposed to be overwritten by themes.

:root {
  /* OVERALL */

  --background-color: theme('colors.gray.100');
  --text-color: theme('colors.gray.900');
  --text-bright-color: theme('colors.gray.700');
  --text-brightest-color: theme('colors.gray.500');
  --link-color: theme('colors.sky.600');
  --link-hover-color: theme('colors.sky.900');
  --icon-color: theme('colors.gray.400');
  --icon-hover-color: theme('colors.gray.500');
  --focus-color: theme('colors.sky.700');
  --border-color: theme('colors.gray.300');
  --shadow-color: theme('boxShadowColor.DEFAULT');
  /* this must be RGB to work */
  --outline-color: 0 0 0;

  --dropdown-menu-background-color: theme('colors.white');
  --dropdown-menu-entry-background-hover-color: theme('colors.gray.100');
  --dropdown-menu-entry-divider-color: theme('colors.gray.200');

  /* APP HEADER */

  --app-header-background-color: theme('colors.white');
  --app-header-notification-icon-color: var(--icon-color, theme('colors.gray.400'));
  --app-header-notification-icon-hover-color: var(--icon-hover-color, theme('colors.gray.500'));
  --app-header-new-notification-indicator-color: theme('colors.blue.400');
  --app-header-menu-button-background-color: theme('colors.gray.100');
  --app-header-menu-button-text-color: theme('colors.gray.300');
  --app-header-mobile-menu-button-text-color: var(--icon-color, theme('colors.gray.400'));
  --app-header-mobile-menu-button-hover-color: var(--icon-hover-color, theme('colors.gray.500'));
  --app-header-mobile-menu-button-background-hover-color: theme('colors.gray.100');
  --app-header-mobile-menu-active-background-color: theme('colors.sky.50');
  --app-header-mobile-menu-active-border-color: theme('colors.sky.500');
  --app-header-mobile-menu-active-text-color: theme('colors.sky.700');
  --app-header-mobile-menu-border-hover-color: var(--border-color, theme('colors.gray.300'));
  --app-header-mobile-menu-background-hover-color: theme('colors.gray.50');

  /* USER SETTINGS */

  --user-settings-view-background-color: var(--background-color);
  --user-settings-view-language-box-background-color: theme('colors.white');

  /* START VIEW */

  --startable-view-background-color: var(--background-color);
  --startable-view-group-title-color: var(--text-brightest-color, theme('colors.gray.500'));

  /* TASK VIEW */

  --task-view-background-color: var(--background-color);
  --task-view-termination-modal-body-background-color: theme('colors.white');
  --task-view-termination-modal-close-icon-text-color: var(--icon-color, theme('colors.gray.400'));
  --task-view-termination-modal-close-icon-text-hover-color: var(--icon-hover-color, theme('colors.gray.500'));
  --task-view-termination-modal-footer-background-color: theme('colors.gray.50');

  /* TASK LIST */

  --task-list-background-color: var(--background-color);
  --task-list-unseen-task-indicator-color: theme('colors.sky.600');
  --task-list-table-header-background-color: theme('colors.gray.50');
  --task-list-table-body-background-color: theme('colors.white');
  --task-list-table-divider-color: theme('colors.gray.200');

  /* CORRELATION TRACKR */

  --correlation-tracker-background-color: var(--background-color);
  --correlation-tracker-table-header-background-color: theme('colors.gray.50');
  --correlation-tracker-table-body-background-color: theme('colors.white');
  --correlation-tracker-table-divider-color: theme('colors.gray.200');

  /* 404 VIEW */

  --404-view-background-color: var(--background-color);

  /* LOGIN PROMPT VIEW */

  --login-prompt-view-background-color: var(--background-color);

  /* SIGN IN/OUT REDIRECT VIEW */

  --signin-redirect-view-background-color: var(--background-color);
  --signout-redirect-view-background-color: var(--background-color);

  /* START PROCESS VIA URL VIEW */

  --start-process-via-url-view-background-color: var(--background-color);

  /* BUTTONS */

  --button-background-color: theme('colors.white');
  --button-border-color: var(--border-color, theme('colors.gray.300'));
  --button-text-color: var(--text-bright-color, theme('colors.gray.700'));
  --button-background-hover-color: theme('colors.gray.50');
  --button-background-disabled-color: theme('colors.gray.200');

  /* USER INTERACTION COMPONENT  */

  --user-interaction-component-background-color: theme('colors.white');
  // --user-interaction-component-text-color
  // --user-interaction-component-link-color
  // --user-interaction-component-link-hover-color
  // --user-interaction-component-border-color
  // --user-interaction-component-focus-color
  // --user-interaction-component-shadow-color

  // --user-interaction-component-header-text-color
  // --user-interaction-component-header-dropdown-icon-text-color
  // --user-interaction-component-header-dropdown-icon-text-hover-color
  // --user-interaction-component-header-dropdown-menu-background-color
  // --user-interaction-component-header-dropdown-menu-entry-background-hover-color
  // --user-interaction-component-header-dropdown-menu-suspend-entry-text-color
  // --user-interaction-component-header-dropdown-menu-suspend-entry-icon-color
  // --user-interaction-component-header-dropdown-menu-terminate-entry-text-color
  // --user-interaction-component-header-dropdown-menu-terminate-entry-icon-color

  // --user-interaction-component-footer-background-color
  // --user-interaction-component-footer-continue-button-text-color
  // --user-interaction-component-footer-continue-button-background-color
  // --user-interaction-component-footer-continue-button-background-hover-color
  // --user-interaction-component-footer-continue-button-focus-outline-color
  // --user-interaction-component-footer-decline-button-text-color
  // --user-interaction-component-footer-decline-button-background-color
  // --user-interaction-component-footer-decline-button-background-hover-color
  // --user-interaction-component-footer-decline-button-focus-outline-color
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  /* ... */
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  /* ... */
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  /* ... */
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  /* ... */
}

html,
body,
#root,
:root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 16px;
}

.app-layout {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'header'
    'content';

  background-color: var(--background-color);
  color: var(--text-color, #333);
  font-size: var(--font-size, 1rem);
}

.app-layout__app-header {
  grid-area: header;
}

.app-layout__content {
  grid-area: content;
  overflow: auto;

  @apply pt-6 px-4 sm:px-12 sm:pt-12 lg:px-40;
}

@import 'startable.scss';

@import 'startable-group.scss';

@import 'task-view.scss';

@import 'startable-list-view.scss';

@import 'task-list.scss';

@import 'correlation-tracker-view.scss';

@import 'user-settings-view.scss';

@import 'login-prompt-view.scss';

@import 'start-dialog-view.scss';

@import '404-view.scss';

@import 'start-process-via-url-view.scss';

.app-layout.app-layout--signin-redirect-view {
  background-color: var(--signin-redirect-view-background-color);
}
.app-layout.app-layout--signout-redirect-view {
  background-color: var(--signout-redirect-view-background-color);
}
